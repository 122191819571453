(function(window, document, undefined) {
    'use strict';

    TRI.home = (function()
    {

        var
        strokeSet = 2
        ,strokeColor = 'rgb(255,255,255)'
        ,strokeColorRed = 255
        ,strokeColorRedCurrent = 255
        ,strokeColorGreen = 255
        ,strokeColorGreenCurrent = 255
        ,strokeColorBlue = 255
        ,strokeColorBlueCurrent = 255
        ,strokeCurrent = 0
        ,backCircle
        ,frontCircle
        ,svgSize = 500
        ,center = svgSize/2
        ,radius = svgSize*0.99/2
        ,radiusFeatures = svgSize*1.25/2
        ,path = ""
        ,arc
        ,startY = center-radius
        ,s
        ,cursorX = 0
        ,cursorY = 0
        ,isHovered = false
        ,anchorSize = 20
        ,featureSize = 56
        ,twCtl
        ,odInstance
        ,odInstanceAlt
        ,expenseAnimDone = false

        var DOM = function(_view)
        {
            this.view               =   (typeof _view != 'undefined')?_view.find('.layout__home'):$('.layout__home');
            this.$slideshow         =   this.view.find('.slideshow')
            this.$controller        =   this.view.find('.slideshow__controller')
            this.$items             =   this.view.find('.slideshow__item')
            this.$timeline          =   this.view.find('.timeline').children()
            this.$anchors           =   this.view.find('.timeline__anchor')
            this.$counter           =   this.view.find('.counter__number')
            this.$counterAlt        =   this.view.find('.counter__numberAlt')
            this.$feature           =   this.view.find('.feature:not(.for--hover)');
            this.$featureHover      =   this.view.find('.feature.for--hover');
            this.$dollars           =   this.view.find('.dollarsTween .symbol');
            this.$loader            =   this.view.find('.main-loader');
            this.$handle            =   this.view.find('.scrollbar .handle');
            this.expenseAnimLocked  =   false;
            this.dollarsAnimLocked  =   true;
            this.loaderAnimLocked   =   false
            this.launchHomeLocked   =   true;
            this.launchHomeAnimLocked   =   false;
            this.init();
        }
        DOM.prototype = {
            channels : function()
            {
                var
                __that = this,
                oldNumber = 0

                _k.subscribe('preload::progress',function(_number)
                {

                    var
                    number = _number * 100
                    // ,totalCounterInit = (_number / 100) * 100
                    ,progress = _number/10
                    ,splitProgress
                    ,context = _k.getViewport()
                    ,moveY =  __that.$loader.find('.unit').children(0).height()
                    ,totalY = __that.$loader.find('.unit').height() - Math.abs(moveY)

                    // totalCounterInit *= 100

                    if(progress == 10) progress = 9.9;

                    splitProgress = progress.toString().split('.')

                    // odInstance.update(totalCounterInit)
                    // odInstanceAlt.update(totalCounterInit)

                    if(typeof splitProgress[1] == 'undefined') splitProgress[1] = 0;

                    if((oldNumber != _number && !__that.loaderAnimLocked) || _number == '100')
                    {
                        TweenLite.killTweensOf(__that.$loader.find('.tenth'));
                        TweenLite.killTweensOf(__that.$loader.find('.unit'));
                        TweenLite.to(__that.$loader.find('.tenth') , 3 , { y : splitProgress[0] * moveY * -1 , ease : Power3.easeInOut , onComplete : function() {
                                __that.loaderAnimLocked = false;
                        } } )
                        TweenLite.to(__that.$loader.find('.unit') , 3 , { y : splitProgress[1] * moveY - totalY , ease : Power3.easeInOut , onComplete : function() {
                                __that.loaderAnimLocked = false;
                        } } )
                        __that.loaderAnimLocked = true;
                    }

                    oldNumber = _number;
                })

                _k.subscribe('preload::complete',function()
                {
                     TweenLite.to(__that.$loader.find('.tenth') , 1 , { autoAlpha : 0 , ease : Cubic.easeInOut , delay : 1.3 })
                     TweenLite.to(__that.$loader.find('.unit') , 1 , { autoAlpha : 0 , ease : Cubic.easeInOut ,  delay : 1.3 })
                    __that.$loader.addClass('is--complete');
                    __that.launchHomeLocked = false;

                })

                _k.subscribe('home::launch',function() {
                    API_transition.introductionExit(__that);
                    setTimeout(function() {
                        odInstance.update(19900)
                        odInstanceAlt.update(19900)
                    }, 1500);
                })

                _k.subscribe('slideshow::between',function(_index)
                {
                    var
                    $ref = __that.$items.eq(_index)
                    ,number = parseInt($ref.data('counter'))
                    ,status = $ref.hasClass('with--image')

                    odInstance.update(number)
                    odInstanceAlt.update(number)
                    __that.updateSkin(status,_index);
                })

                _k.subscribe('slideshow::updateFeature',function(_index)
                {
                    __that.updateFeature(_index);
                })

                _k.subscribe('animation::expense',function()
                {
                    if(!__that.expenseAnimLocked)
                    {
                        __that.view.find('.for--expense .slideshow__preview').removeClass('fxscroll');
                        API_transition.expenseAnimation(__that);
                        expenseAnimDone = true;
                    }

                })

                _k.subscribe('animation::try',function()
                {
                    __that.view.addClass('is--ended');
                    if(!$master.hasClass('final--skin')) $master.addClass('final--skin');
                    __that.dollarsAnimLocked = false;
                    API_transition.tryAnimation(__that);
                })

                _k.subscribe('timeline::show',function()
                {
                    __that.view.removeClass('is--ended');
                    if($master.hasClass('final--skin')) $master.removeClass('final--skin');
                    __that.resetDollarsTween();
                })

                _k.subscribe('window::smartresize',function() {
                    __that.drawScrollbar();
                })
            }
            ,
            counter : function()
            {
                var
                __that = this

                odInstance = new Odometer({
                    el: __that.$counter[0],
                    value: 99999,
                    format: "ddd.dd",
                    theme: "default",
                    duration : 300,
                })


                odInstanceAlt = new Odometer({
                    el: __that.$counterAlt[0],
                    value: 100000,
                    format: "ddd.dd",
                    theme: "default",
                    duration : 300,
                })

            }
            ,
            draw : function()
            {
                s = Snap('#timelineSvg')
                arc = s.path(path)

                backCircle = s.circle(center, center, radius);
                backCircle.attr({
                    fill: "none",
                    stroke: "rgba(255,255,255,.5)",
                    strokeWidth: 2,
                    class:'shape'
                });
            }
            ,
            drawAnchor : function(_angle,_ref)
            {
                var
                rad = Math.radians(_angle)
                ,x = Math.cos(rad) * radius + (center - anchorSize/2)
                ,y = Math.sin(rad) * radius + (center - anchorSize/2)

                _ref.css({
                    'left': Math.round(x),
                    'top' : Math.round(y)
                })

            }
            ,
            drawFeature : function(_angle)
            {
                var
                rad = Math.radians(_angle - 90)
                ,x = Math.cos(rad) * radiusFeatures + (center - featureSize/2)
                ,y = Math.sin(rad) * radiusFeatures + (center - featureSize/2)

                this.$feature.css({
                    'left': Math.round(x),
                    'top' : Math.round(y)
                })

                TweenLite.set(this.view.find('.feature__line:not(.for--hover)') , { x : x , y : y , rotation : _angle , force3D : true })

            }
            ,
            drawScrollbar : function()
            {
                if($('html').hasClass('webkit'))
                {
                    var
                    context = _k.getViewport()
                    ,getTotalHeight = this.$items.length*context.height
                    ,getRatio = context.height / getTotalHeight
                    ,getScrollbarHeight

                    getRatio = getRatio.toFixed(2)
                    getScrollbarHeight = Math.round(context.height *getRatio)

                    this.$handle.css('height',getScrollbarHeight)
                }
            }
            ,
            drawTimeline : function(percent)
            {
                var endpoint = percent*360;
                arc.remove();
                var d = endpoint,
                    dr = d-90,
                    radians = Math.PI*(dr)/180,
                    endx = center + radius*Math.cos(radians),
                    endy = center + radius * Math.sin(radians),
                    largeArc = d>180 ? 1 : 0;
                    path = "M"+center+","+startY+" A"+radius+","+radius+" 0 "+largeArc+",1 "+endx+","+endy;

                // thickness of the path
                strokeCurrent += (strokeSet - strokeCurrent) / 4;
                // color rgb of the path
                strokeColorRedCurrent += (strokeColorRed - strokeColorRedCurrent) / 20;
                strokeColorGreenCurrent += (strokeColorGreen - strokeColorGreenCurrent) / 20;
                strokeColorBlueCurrent += (strokeColorBlue - strokeColorBlueCurrent) / 20;

                // get real value of the color
                var
                realRed = strokeColorRedCurrent.toString().split('.')[0]
                ,realGreen = strokeColorGreenCurrent.toString().split('.')[0]
                ,realBlue = strokeColorBlueCurrent.toString().split('.')[0]

                arc = s.path(path);
                arc.attr({
                    stroke: 'rgb('+realRed+','+realGreen+','+realBlue+')',
                    fill: 'none',
                    strokeWidth: strokeCurrent,
                    class: 'line'
                });
            }
            ,
            events : function()
            {
                var
                __that = this

                __that.$slideshow.on('mousemove.home',function(e) {
                    cursorX = e.pageX;
                    cursorY = e.pageY;
                })

                __that.$anchors.on('click',function()
                {
                    var
                    index = $(this).data('index')

                    _k.publish('slideshow::goto',index);
                })


                __that.$anchors.hover(
                    function()
                    {
                        if(!$(this).hasClass('is--alternative'))
                        {
                            __that.hoverFeatures($(this).attr('data-angle'),parseInt($(this).attr('data-index')))

                            __that.view.find('.timeline').addClass('is--hovered')
                        }
                         TweenLite.to($(this),.6,{scale : 1.3 , ease : Back.easeInOut })
                    }
                    ,
                    function()
                    {
                        TweenLite.to($(this),.6,{scale : 1 , ease : Back.easeInOut })
                        __that.view.find('.timeline').removeClass('is--hovered')
                    }
                )

                $('.jsTrigger-backHome').addClass('disabled')

                this.view.find('.jsTrigger--homeLaunch').on('click',function() {
                    _k.publish('home::launch')
                });

                this.$loader.on('mousewheel.loader',function() {
                    if(!__that.launchHomeLocked && !__that.launchHomeAnimLocked) _k.publish('home::launch');
                });


            }
            ,
            fxOnScroll : function(_totalScroll,_scrollTop)
            {
                var
                $els = this.view.find('.fxscroll')
                ,viewport = _k.getViewport()
                ,getHeight = (isMobile || isTablet)?$(document).height():viewport.height


                $els.each(function() {
                    var
                    $ref = $(this)
                    ,datas = $ref.data()
                    ,index = $ref.closest('.slideshow__item').index()
                    ,offsetTop = getHeight * index - getHeight / 2
                    ,offsetBottom = offsetTop + (getHeight + getHeight / 2)
                    ,ratio = (( _scrollTop - offsetTop ) / getHeight) * 2 // multiply by two to start from 0, the perfect position is 1 and the end of is 2
                    ,pos = datas.cssto * ratio
                    ,opacity = ratio
                    ,from = datas.cssfrom * (1 - ratio)

                    if(opacity > 1) opacity = 2 - opacity;

                    if(_scrollTop > offsetTop && _scrollTop < offsetBottom)
                    {
                        if( datas.css == 'y' )
                        {
                            TweenLite.set($ref , { y : (pos + from) * datas.inertia , force3D : true });
                        }
                        else if( datas.css == 'x' )
                        {
                            TweenLite.set($ref , { x : (pos + from) * datas.inertia , force3D : true });
                        }
                        if( datas.opacity == '1' )
                        {
                            TweenLite.set($ref , { opacity : opacity });
                        }

                        // console.log((_scrollTop - offsetTop)  / getHeight)
                        // console.log(_scrollTop , offsetTop , getHeight + (getHeight / 2))
                    }

                });

                var
                posYHandle = (getHeight - this.$handle.height()) * _totalScroll
                TweenLite.set(this.$handle , { y : posYHandle })

            }
            ,
            hoverFeatures : function(_angle,_index)
            {
                var
                __that = this
                ,klass

                __that.$featureHover.removeClass('for--booking for--syncing for--tracking for--expense')
                switch(_index) {
                    case 0:case 1:
                        klass = 'for--booking';
                        __that.$featureHover.find('.feature_description .type').html('Hotel Booking')
                        __that.$featureHover.find('.feature_description .value').html('$199.00')
                        break;
                    case 2:case 3:
                        __that.$featureHover.find('.feature_description .type').html('Flight Booking')
                        __that.$featureHover.find('.feature_description .value').html('$249.00')
                        klass = 'for--syncing';
                        break;
                    case 4:case 5:
                        __that.$featureHover.find('.feature_description .type').html('Car Rental')
                        __that.$featureHover.find('.feature_description .value').html('$199.00')
                        klass = 'for--tracking';
                        break;
                    case 6:case 7:
                        __that.$featureHover.find('.feature_description .type').html('Restaurant')
                        __that.$featureHover.find('.feature_description .value').html('$89.00')
                        klass = 'for--expense';
                        break;
                }

                __that.$featureHover.addClass(klass);


                var
                rad = Math.radians(_angle - 90)
                ,x = Math.cos(rad) * radiusFeatures + (center - featureSize/2)
                ,y = Math.sin(rad) * radiusFeatures + (center - featureSize/2)

                this.$featureHover.css({
                    'left': Math.round(x),
                    'top' : Math.round(y)
                })

                TweenLite.set(this.view.find('.feature__line.for--hover') , { x : x , y : y , rotation : _angle , force3D : true })
            }
            ,
            hoverTimeline : function()
            {
                var
                viewport=_k.getViewport()
                ,position=this.$controller.offset()

                if( ( cursorX > position.left ) && ( cursorX < position.left + this.$controller.width() ) && ( cursorY > position.top ) && ( cursorY < position.top + this.$controller.height() ) )
                {
                    strokeSet = 4;
                    backCircle.attr({
                        strokeWidth: 3
                    })
                    isHovered = true;
                }
                else
                {
                    strokeSet = 3;
                    backCircle.attr({
                        strokeWidth: 2
                    })
                    isHovered = false;
                }

                if($master.hasClass('alternative--skin'))
                {
                    strokeColorRed = 12;
                    strokeColorGreen = 123;
                    strokeColorBlue = 248;
                }
                else
                {
                    strokeColorRed = 255;
                    strokeColorGreen = 255;
                    strokeColorBlue = 255;
                }
            }
            ,
            listen : function()
            {
                var
                __that = this

                ,h=(isTablet || isMobile)?$(document).height():_k.getViewport().height

                RAF = requestAnimFrame(function() {
                    __that.listen();
                })

                var
                getFullScroll = __that.$items.length * h - h
                ,getContentScroll = __that.$slideshow.scrollTop()
                ,ratio = getContentScroll / getFullScroll
                ,angle = ratio * 360


                __that.drawTimeline(ratio);
                __that.hoverTimeline();
                __that.fxOnScroll(ratio,getContentScroll);
                if(!this.$timeline.parent().hasClass('is--hovered')) __that.drawFeature(angle);

            }
            ,
            resetDollarsTween : function()
            {
                var
                __that = this

                if(!this.dollarsAnimLocked)
                {
                    TweenLite.killTweensOf(this.$dollars);
                    this.dollarsAnimLocked = true;
                    setTimeout(function() {
                        TweenLite.set(__that.$dollars , { clearProps : "all" })
                    }, 1000);
                }
            }
            ,
            updateFeature : function(_index)
            {
                var
                __that = this
                ,klass

                __that.$feature.removeClass('for--booking for--syncing for--tracking for--expense')
                switch(_index) {
                    case 0:case 1:
                        klass = 'for--booking';
                        __that.$feature.find('.feature_description .type').html('Hotel Booking')
                        __that.$feature.find('.feature_description .value').html('$199.00')
                        break;
                    case 2:case 3:
                        __that.$feature.find('.feature_description .type').html('Flight Booking')
                        __that.$feature.find('.feature_description .value').html('$249.00')
                        klass = 'for--syncing';
                        break;
                    case 4:case 5:
                        __that.$feature.find('.feature_description .type').html('Car Rental')
                        __that.$feature.find('.feature_description .value').html('$199.00')
                        klass = 'for--tracking';
                        break;
                    case 6:case 7:
                        __that.$feature.find('.feature_description .type').html('Restaurant')
                        __that.$feature.find('.feature_description .value').html('$89.00')
                        klass = 'for--expense';
                        break;
                }

                __that.$feature.addClass(klass);
            }
            ,
            updateSkin : function(_type,_index)
            {
                var
                __that = this
                ,klass

                if(_type)
                {
                    $master.removeClass('alternative--skin');
                }
                else
                {
                    $master.addClass('alternative--skin');
                }
                __that.$handle.removeClass('for--booking for--syncing for--tracking for--expense for--final')
                switch(_index) {
                    case 0:case 1:
                        klass = 'for--booking';
                        break;
                    case 2:case 3:
                        klass = 'for--syncing';
                        break;
                    case 4:case 5:
                        klass = 'for--tracking';
                        break;
                    case 6:case 7:
                        klass = 'for--expense';
                        break;
                    case 8:
                        klass = 'for--final';
                        break;
                }
                __that.$handle.addClass(klass);
            }
            ,
            init : function()
            {
                var
                __that = this
                ,withIntro

                this.channels();
                this.counter();
                this.draw();
                this.drawScrollbar();
                this.events();
                this.listen();
                this.updateFeature(0)
                this.$handle.addClass('for--booking')
                this.view.scrollTop(0)

                __that.$anchors.each(function()
                {
                    var
                    $ref = $(this)
                    ,angleStart = $ref.data('start')

                    __that.drawAnchor(angleStart,$ref);
                })


                API_transition.introductionStart(__that);

            }
        }

        // User experience
        //================
        var Slideshow = function(_view) {
            this.view           =   (typeof _view != 'undefined')?_view.find('.layout__home'):$('.layout__home');
            this.currentStrat   =   0;
            this.locked         =   true;
            this.$slideshow     =   this.view.find('.slideshow');
            this.stratums       =   this.view.find('.slideshow__item');
            this.$anchors       =   this.view.find('.timeline__anchor')
            this.total          =   this.stratums.length - 1;
            this.wheelEvent     =   'mousewheel.home';
            this.keyEvent       =   'keydown.home';
            this.init();
        }
        Slideshow.prototype = {
            channels : function()
            {
                var
                __that = this

                _k.subscribe('slideshow::unlocked',function()
                {
                    __that.locked = false;
                })

                _k.subscribe('slideshow::goto',function(_index)
                {
                    __that.goTo(_index);
                })
            }
            ,
            checkLastSlide : function()
            {
                if(this.currentStrat == 8)
                {
                    _k.publish('animation::try')
                }
                else
                {
                    _k.publish('timeline::show')
                }
            }
            ,
            events : function() {
                var
                    scope       =   this
                ;
                if(isTablet)
                {
                    this.swipeOn();
                    document.addEventListener('touchmove',function(e) {
                        e.preventDefault();
                    });
                }
                else
                {
                    this.wheelOn();
                }
                $body.on(scope.keyEvent,function(e) {
                    switch(e.which) {
                        case 38:
                            scope.wheelTask(null,1);
                            e.preventDefault();
                            return false;
                        break;
                        case 40:
                            scope.wheelTask(null,-1);
                            e.preventDefault();
                            return false;
                        break;
                    }

                });
            }
            ,
            goTo : function(_index)
            {
                if(!this.locked)
                {
                    var
                    dir

                    if(_index > this.currentStrat)
                    {
                        dir = 1
                    }
                    else
                    {
                        dir = -1;
                    }

                    this.currentStrat   =   _index;
                    API_transition.slideshowHome(this,dir);
                    this.checkLastSlide();
                }
            }
            ,
            wheelOn : function()
            {
                var
                    scope       =   this
                ;
                var wheel = _k.killbounce(function(a,b) {
                    scope.wheelTask(a,b);
                }, 50);
                this.view.on(scope.wheelEvent, function(_e,_direction) {
                    wheel(_e,_direction);
                    return false;
                });
            }
            ,
            wheelTask : function(_event,_direction)
            {
                var
                    Event       =   _event
                ,   scope       =   this
                ;
                if(!scope.locked)
                {
                    if(_direction >= 1)
                    {
                        scope.currentStrat  -=  1;
                    }
                    else
                    {
                        scope.currentStrat  +=  1;
                    }
                    API_transition.slideshowHome(scope,_direction);
                    scope.checkLastSlide();
                }
            }
            ,
            setup : function() {
                var
                    scope       =   this
                ;
                this.store();
                this.$slideshow.scrollTop(0);
            }
            ,
            store : function() {
                this.view.data('index',this.currentStrat);
            }
            ,
            swipeOn : function() {
                var
                    refID       =   document.getElementById('main-content')
                ,   scope       =   this
                ;
                Hammer(refID).on("swipeup", function(event) {
                    scope.wheelTask(event,-1);
                });
                Hammer(refID).on("swipedown", function(event) {
                    scope.wheelTask(event,1);
                });
            }
            ,
            update : function() {
                this.stratums.removeClass('disabled enabled');
                this.stratums.css({
                    'z-index' : 0
                }).addClass('disabled');
                this.stratums.eq(this.currentStrat).css({
                    'z-index':1
                }).removeClass('disabled').addClass('enabled');
                ;
                if(this.currentStrat == 7 && !expenseAnimDone)
                {
                    this.locked = true;
                    _k.publish('animation::expense')
                }
            }
            ,
            updateAnchor : function()
            {
                this.$anchors.removeClass('active')
                for(var i=0;i<=this.currentStrat;i++)
                {
                    var
                    klass = '.jsElement--anchor'+i

                    $(klass).addClass('active')
                }
            }
            ,
            init : function() {
                this.setup();
                this.channels();
                this.update();
                this.events();
            }
        }


        var init = function()
        {
            new DOM();
            new Slideshow();
        }

        var after = function(_view)
        {
            if($master.hasClass('is--ready')) $master.removeClass('is--ready')
            new DOM(_view);
            new Slideshow(_view);
        }

        return {
                init : init
            ,   after : after
        }
    })();

}(window, document));