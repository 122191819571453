(function(window, document, undefined) {
    'use strict';

    TRI.pricing = (function()
    {


        var init = function()
        {
            if(!$master.hasClass('is--ready')) $master.addClass('is--ready')
            if(!$master.hasClass('alternative--skin')) $master.addClass('alternative--skin')
        }

        var after = function(_view)
        {
            if(!$master.hasClass('is--ready')) $master.addClass('is--ready')
            if(!$master.hasClass('alternative--skin')) $master.addClass('alternative--skin')
        }

        return {
                init : init
            ,   after : after
        }
    })();

}(window, document));