(function(window, document, undefined) {
    'use strict';

    TRI.layout = (function()
    {


        // Global
        //=======
        var Global = function() {

            this.init();
        };
        Global.prototype = {
            channels : function() {
                var
                    global      =   this
                ;
                _k.subscribe('mainnav::update',function(_controller) {
                    global.mainnavUpdate(_controller);
                });
                _k.subscribe('statechange::before',function() {
                    global.offAll();
                    global.reset();
                });
            }
            ,
            offAll : function() {
                $.each(EVENTS,function() {
                    $.each(this,function() {
                        if(this.indexOf('keydown') >= 0)
                        {
                            $body.off(this);
                        }
                        else
                        {
                            $mainContent.off(this);
                        }
                    });
                });
                _k.unsubscribe('window::smartresize');
                cancelRequestAnimFrame(RAF);
                cancelRequestAnimFrame(RAFScroll);
                $master.removeClass('alternative--skin')
                allowPreventTouch   =   false;
                $('.jsTrigger-backHome').removeClass('disabled')
            }
            ,
            mainnavUpdate : function(_controller)
            {
                // console.log('mainnav update',_controller);
                $mainNav.find('li').removeClass('active');
                switch(_controller) {
                    case 'home':
                        $subNav.find('li').removeClass('active');
                        $mainNav.find('li').eq(0).addClass('active');
                        break;
                    case 'features':
                        $subNav.find('li').removeClass('active');
                        $mainNav.find('li').eq(1).addClass('active');
                        break;
                    case 'pricing':
                        $subNav.find('li').removeClass('active');
                        $mainNav.find('li').eq(2).addClass('active');
                        break;
                }
                if(isTablet || isMobile) $mainNav.trigger('mouseleave')
            }
            ,
            reset : function() {
                $mainContent.removeClass('canScroll');
                TRI.core.smartResize();
            }
            ,
            setup : function()
            {
                var
                    getController       =   $mainContent.attr('data-controller')
                ;
                this.mainnavUpdate(getController);
            }
            ,
            init : function() {
                this.channels();
                this.setup();
            }
        }


        // Transition finalize
        //====================
        var transitionFinalize = function(_controller)
        {
            console.log('transitionFinalize :',_controller);
        }

        // Initialize the template
        //=======================
        var init = function()
        {
            new Global();
        }

        return {
                init: init
            ,   transitionFinalize:transitionFinalize
        }
    })();

}(window, document));