(function(window, document, undefined) {
    'use strict';

    TRI.navigation = (function()
    {

        var Mainnav = function()
        {
            this.locked     =   false;
            this.init();
        }
        Mainnav.prototype = {
            events : function()
            {
                var
                __that = this

                if(isTablet || isMobile)
                {

                    Hammer($('.jsTrigger--mainNav')[0]).on("tap", function(event) {
                        __that.toggle();
                    });
                    Hammer($mainContent[0]).on("tap", function(event) {
                        var
                        getStatus = $mainNav.hasClass('active')

                        if( getStatus)__that.toggle();
                    });
                }
                else
                {
                    $('.jsTrigger--mainNav').on('mouseenter',function()
                    {
                        var
                        getStatus = $mainNav.hasClass('active')

                        if(!getStatus && !__that.locked)
                        {
                            __that.toggle();
                            __that.locked = true;
                        }
                    });
                    $mainNav.on('mouseleave',function(e)
                    {
                        var
                        getStatus = $mainNav.hasClass('active')

                        if(getStatus) __that.toggle();
                    });
                }

            }
            ,
            toggle : function()
            {
                var
                getStatus = $mainNav.hasClass('active')
                ,__that = this

                if(getStatus)
                {
                    $mainNav.removeClass('active');
                    $master.removeClass('mainnav--opened')
                    $('.jsReceptor--mainNav').removeClass('active')
                }
                else
                {
                    $mainNav.addClass('active');
                    $master.addClass('mainnav--opened')
                    $('.jsReceptor--mainNav').addClass('active')
                }
                setTimeout(function() {
                    __that.locked = false;
                }, 1000);
            }
            ,
            init : function()
            {
                this.events();
            }
        }

        var init = function()
        {
            new Mainnav();
        }

        return {
                init : init
        }
    })();

}(window, document));