(function(window, document, undefined) {
    'use strict';

    TRI.annex = (function()
    {

        // active item in submenu for first visit
        var activeInit = function(_index)
        {
            setTimeout(function() {
                $subNav.find('li').eq(_index).addClass('active')
            }, 0);
        }

        // get anchor
        var getContext = function()
        {
            var
            url = window.location.href
            ,parseUrl = url.split('/')
            ,segment = parseUrl[parseUrl.length - 1]
            ,i = null

            switch(segment)
            {
                case 'privacy':
                    i = 1;
                    break;
                case 'terms':
                    i = 2;
                    break;
                case 'security':
                    i = 3;
                    break;
            }
            return i;
        }

        //
        var listen = function()
        {
            var
            __that = this

            RAFScroll = requestAnimFrame(function() {
                listen();
            })

            var
            getY = $('.layout__annex').scrollTop()
            if(getY > 260)
            {
                if(!$master.hasClass('alternative--skin')) $master.addClass('alternative--skin')
            }
            else
            {
                if($master.hasClass('alternative--skin')) $master.removeClass('alternative--skin')
            }
        }

        // update subnav
        var update = function(_index)
        {
            var
            segment = getContext()
            ,i = 1

            $subNav.find('li').removeClass('active');
            if(typeof _index != 'undefined')
            {
                i = _index ;
                $subNav.find('li').eq((i+1)).addClass('active')
            }
            else
            {
                i = segment;
                $subNav.find('li').eq(i).addClass('active')
            }
            return i;
        }



        var init = function()
        {
            if(!$master.hasClass('is--ready')) $master.addClass('is--ready')
            var getIndex = getContext();
            new TRI.slideshow.Generic(undefined,{ autoplay : false , onUpdate : update , start : getIndex - 1 });
            listen();
        }

        var after = function(_view)
        {
            if(!$master.hasClass('is--ready')) $master.addClass('is--ready')
            var getIndex = getContext();
            new TRI.slideshow.Generic(_view,{ autoplay : false , onUpdate : update , start : getIndex - 1 });
            activeInit(getIndex);
            listen();
        }

        return {
                init : init
            ,   after : after
        }
    })();

}(window, document));