(function(window, document, undefined) {
    'use strict';

    TRI.preload = (function()
    {
        var manifest = function()
        {
            if(!$HTML.hasClass('ie'))
            {
                var
                $receptorVideos = $('.slideshow__item.with--image')
                ,i = 0
                ,total = 0

                $.html5Loader({
                    filesToLoad: config.pathAssets+'/manifest.json',
                    onBeforeLoad : function () {
                        // console.log('before');
                    },
                    onComplete :         function () {
                        setTimeout(function() {
                            _k.publish('preload::complete')
                        }, 2000);

                    },
                    onElementLoaded : function ( _obj, _el) {
                        if(i<4 && _obj.type == "VIDEO")
                        {
                            var
                            getReceptor = _obj.source.split('--')[0]
                            ,$video = $(_el)

                            getReceptor = parseInt(getReceptor.substr(-1))
                            $video.prop({
                                'preload' : 'auto',
                                'controls' : '',
                                'autoplay' : '1',
                                'loop' : '1',
                                'muted' : 'muted'
                            }).attr({
                                'class' : 'videobg',
                                'height' : '100%',
                                'width' : '100%'
                            }).appendTo($receptorVideos.eq(getReceptor - 1))
                            $receptorVideos.eq(getReceptor - 1).find('.bg').addClass('is--ready');
                            $video[0].play()
                            i++
                        }
                        total++

                    },
                    onUpdate : function ( percentage ) {
                        _k.publish('preload::progress',percentage)
                    }
                });
            }
            else
            {
                _k.publish('preload::progress',100);
                setTimeout(function()
                {
                    _k.publish('preload::complete')
                }, 1200);

            }
        }

        var manifestShort = function()
        {
            if(!$HTML.hasClass('ie'))
            {
                var
                $receptorVideos = $('.slideshow__item.with--image')
                ,i = 0
                ,total = 0

                $.html5Loader({
                    filesToLoad: config.pathAssets+'/manifest.json',
                    onBeforeLoad : function () {
                        // console.log('before');
                    },
                    onComplete :         function () {

                    },
                    onElementLoaded : function ( _obj, _el) {
                        if(i<4 && _obj.type == "VIDEO")
                        {
                            var
                            getReceptor = _obj.source.split('--')[0]
                            ,$video = $(_el)

                            getReceptor = parseInt(getReceptor.substr(-1))
                            $video.prop({
                                'preload' : 'auto',
                                'controls' : '',
                                'autoplay' : '1',
                                'loop' : '1',
                                'muted' : 'muted'
                            }).attr({
                                'class' : 'videobg',
                                'height' : '100%',
                                'width' : '100%'
                            }).appendTo($receptorVideos.eq(getReceptor - 1))
                            $receptorVideos.eq(getReceptor - 1).find('.bg').addClass('is--ready');
                            $video[0].play()
                            i++
                        }
                        total++

                    }
                });
            }

        }

        return {
            manifest:manifest,
            manifestShort:manifestShort
        }
    })();

}(window, document));

