(function(window, document, undefined) {
    'use strict';
    /*
     * Global api.
     */
    var transition = window.transition = {
        get: function() {
            return _instance;
        },
        //Main entry point.
        init: function(options) {
            return _instance || new Transition(options);
        }
    };

    /**
     * Constructor.
     */
    function Transition(options) {
        _instance = this;
        return _instance;
    }

    // Expense animation
    //==================
    Transition.prototype.expenseAnimation = function(_scope)
    {
        var
            scope           =   _scope
        ,   duration        =   .8
        ,   timeline        =   new TimelineLite({
                paused : true,
                onComplete : function() {
                    scope.expenseAnimLocked = true;
                    _k.publish('slideshow::unlocked')
                }
            })
        ,   $ref            =   scope.view.find('.for--expense')
        ,   $flash          =   $(document.getElementById('flashExpense'))


        // timeline
        timeline.kill();
        timeline.add([
            TweenLite.to($ref.find('.slideshow__preview').eq(0), duration , { x : 0 , y : 0 , left : '50.36%' , top : '50%' , ease : Power3.easeInOut }),
            TweenLite.to($ref.find('.slideshow__preview').eq(1), duration , { x : 0 , y : 0 , left : '50%' , top : '42%' , ease : Power3.easeInOut })
        ])
        timeline.add([
            TweenLite.fromTo($flash, .2 , { autoAlpha : 0 } , { autoAlpha : 1 , ease : Expo.easeInOut , onComplete : changePicture , delay : .7 })
        ])
        timeline.add([
            TweenLite.to($flash, .2 , { autoAlpha : 0 , ease : Expo.easeInOut })
        ])

        timeline.restart();

        // functions
        function changePicture()
        {
            var
            $photo = $ref.find('.slideshow__preview').eq(0)
            $photo.attr('src',$photo.data('change'))

            $ref.find('.slideshow__preview').eq(1).addClass('visuallyhidden')
        }
    }

    // Global
    //=======
    Transition.prototype.global = function(_controller)
    {
        var
            timeline            =   new TimelineLite({
                paused : true,
                onComplete : complete
            })
        ,   duration            =   .6
        ,   $ref                =   $(document.getElementById('main-transition'))
        ,   $current            =   $mainContent.find('.the-view:not(.ajaxnext)')
        ,   $next               =   $mainContent.find('.ajaxnext')
        ,   gap                 =   $mainNav.hasClass('active')?$mainNav.width():0
        ,   easing              =   Quart.easeInOut
        ,   context             =   _k.getViewport()


        // Preset
        TweenLite.set($ref , { autoAlpha : 1 , x : (context.width - gap) * -1 , force3D : true })

        // timeline
        timeline.kill();
        timeline.add([
            TweenLite.to($ref, duration , { x : 0 , ease : easing , force3D : true } )
        ]);
        timeline.call(function() {
            $current.remove();
            $next.removeClass('ajaxnext ajaxoverride ajaxhidden');
        });
        timeline.add([
            TweenLite.to($ref, .8 , { x : context.width , ease : easing , force3D : true } )
        ]);
        timeline.restart();

        //
        function complete()
        {
            // TRI.preload.manifest();
        }

    }

    // Introduction Start
    //==================
    Transition.prototype.introductionStart = function(_scope)
    {
        var
            scope           =   _scope
        ,   timeline            =   new TimelineLite({
                paused : true,
                onStart : start,
                onComplete : complete
            })
        ,   duration            =   1
        ,   easing              =   Quart.easeInOut
        ,   $ref                =   scope.$loader
        ,   $counterTitle1      =   scope.view.find('.counter__title span').eq(0)
        ,   $counterSubtitle    =   scope.view.find('.counter__subtitle span')
        ,   $dots               =   $ref.find('.dots .dot')
        ,   context             =   _k.getViewport()
        ,   moveY               =   $ref.find('.unit').height() - $ref.find('.unit').children(0).height()
        ,   totalY              =   moveY * -1

        // Preset
        TweenLite.set(scope.view.find('.counter__title') , { autoAlpha : 1 })
        TweenLite.set(scope.view.find('.counter__subtitle') , { autoAlpha : 1 })
        TweenLite.set($ref.find('.dots') , { autoAlpha : 1 })

        // timeline
        timeline.kill();
        timeline.add([
            TweenLite.fromTo($ref.find('.progress .value').eq(0), 1 , { autoAlpha : 0 , y : 20 } , { autoAlpha : .1 , y : 0 , ease : Sine.easeOut } ),
            TweenLite.fromTo($ref.find('.progress .value').eq(1), 1 , { autoAlpha : 0 , y : totalY - 20  } , { autoAlpha : .1 , y : totalY  , ease : Sine.easeOut } ),
            TweenLite.fromTo($ref.find('.logofull'), 1 , { autoAlpha : 0  } , { autoAlpha : 1 , ease : Sine.easeOut , delay : .4 } ),
            TweenLite.fromTo($ref.find('.line'), 1 , { scaleX : 0 , autoAlpha : .2 } , { scaleX : 1 , ease : Sine.easeInOut } ),
            TweenLite.set($counterTitle1, { x : '150%' , autoAlpha : 0 } ),
            TweenLite.fromTo($counterSubtitle, 1 , { y : -100  } , { y : 40 ,  ease : Quart.easeInOut } ),
            TweenLite.fromTo($dots.eq(0), 1 , { x : 0  } , { x : 600 ,  ease : Power3.easeInOut } ),
            TweenLite.fromTo($dots.eq(1), 1 , { x : 0  } , { x : 300 ,  ease : Power3.easeInOut , delay : .2 } ),
            TweenLite.fromTo($dots.eq(2), 1 , { x : 0  } , { x : -600 ,  ease : Power3.easeInOut } ),
            TweenLite.fromTo($dots.eq(3), 1 , { x : 0  } , { x : -300 ,  ease : Power3.easeInOut , delay : .2 } ),
        ])

        timeline.restart();

        //
        function start()
        {
            setTimeout(function() {
                TweenLite.set(scope.view.find('.counter') , { opacity : 1 })
            }, 1000);
        }

        function complete()
        {
            TRI.preload.manifest();
        }

    }

    // Introduction Exit
    //==================
    Transition.prototype.introductionExit = function(_scope)
    {
        var
            scope           =   _scope
        ,   timeline        =   new TimelineLite({
                paused : true,
                onComplete : complete
        })
        ,   $anchors        =   scope.$anchors
        ,   $loader         =   scope.$loader
        ,   $counterTitle1  =   scope.view.find('.counter__title span').eq(0)
        ,   $counterTitle2  =   scope.view.find('.counter__title span').eq(1)
        ,   $counterSubtitle    =   scope.view.find('.counter__subtitle span')
        ,   $dots           =   $loader.find('.dots .dot')
        ,   $timeline       =   scope.view.find('.timeline')
        ,   context         =   _k.getViewport()
        ,   scaleLine       =   500 / context.width

        scope.launchHomeAnimLocked = true;
        $counterSubtitle.css('overflow','visible')

        timeline.kill();
        timeline.add([
            TweenLite.set($loader.find('.button'), { opacity : 0 , y : 20 } ),
            TweenLite.to($loader.find('.logofull'), 1 , { autoAlpha : 0 , ease : Sine.easeOut , delay : .6 } ),
            TweenLite.to($loader.find('.line'), 1 , { scaleX : scaleLine , ease : Power3.easeInOut } ),
            TweenLite.to($dots.eq(0), .8 , { x : 247 ,  ease : Power3.easeInOut , delay : .2  } ),
            TweenLite.to($dots.eq(1), 1 , { x : 247 ,  ease : Power3.easeInOut } ),
            TweenLite.to($dots.eq(2), .8 , { x : -247 ,  ease : Power3.easeInOut , delay : .2 } ),
            TweenLite.to($dots.eq(3), 1 , { x : -247 ,  ease : Power3.easeInOut  } ),
            TweenLite.to($counterSubtitle, 1.4 , { x : -100 , autoAlpha : 0 , ease : Quart.easeInOut } ),

        ])
        timeline.add([
            TweenLite.fromTo($loader.find('.fill'), 1.2 , { clip : 'rect(0,'+context.width+'px,'+context.height+'px,0)' } , {  clip : 'rect('+context.height+'px,'+context.width+'px,'+context.height+'px,0)' , ease : Power3.easeInOut , delay : .2 } ),
            TweenLite.fromTo(scope.view.find('.slideshow'), 1.4 , { y : -500 , z : 0 } , {  y : 0 , z: 0 , force3D : true ,  ease : Power3.easeInOut } ),
            TweenLite.fromTo(scope.view.find('.counter__currency') , .5 , { autoAlpha : 0 } , { autoAlpha : 1 , delay : .5 } ),
            TweenLite.fromTo(scope.view.find('.counter__number') , .5 , { autoAlpha : 0 } , { autoAlpha : 1 , delay : .5 } ),
            TweenLite.fromTo(scope.view.find('.timeline__anchors'), 1 , { autoAlpha : 0 } , { autoAlpha : 1  } ),
            TweenLite.to($loader.find('.line'), 1.4 , { rotation : 90 , autoAlpha : 0 , ease : Quart.easeInOut } ),
            TweenLite.to($counterTitle1, 1.4 , { x : '-50%' , autoAlpha : 1 , ease : Quart.easeInOut } ),
            TweenLite.fromTo($('#tweenController') , 1.4 , { scale : 0 } , { scale : 1 , ease : Quart.easeInOut , onUpdate : update , onUpdateParams:["{self}"] } )
        ],'-=.1')
        timeline.restart();

        //
        function update(_el)
        {
            var
            progress = this._targets[0]._gsTransform.scaleX / 1
            ,clipX = 500 * progress
            ,clipXReverse = 500 - (500 * progress)


            TweenLite.set($loader.find('.circle'), {  clip : 'rect(0px,'+clipX+'px,250px,0)'  });
            TweenLite.set($loader.find('.circleReverse'), {  clip : 'rect(250px,500px,500px,'+clipXReverse+'px)' });

            if(clipX == 500)
            {
                $loader.find('.circle').remove();
                $loader.find('.circleReverse').remove();
                $timeline.addClass('is--ready')
            }

            $anchors.each(function(i)
            {
                var
                $ref = $(this)
                ,angleStart = $ref.data('start')
                ,angleEnd = $ref.data('end')
                if(i<4)
                {
                    var
                    distanceToDo = angleEnd - angleStart
                    ,realValue = (distanceToDo * progress) + angleStart
                }
                else
                {
                    var
                    realValue = (angleEnd * progress)
                }
                scope.drawAnchor(realValue,$ref);
            })
        }

        function complete()
        {
            $loader.remove();
            $master.addClass('is--ready')
            _k.publish('slideshow::unlocked');
        }
    }

    // Introduction Exit
    //==================
    Transition.prototype.noIntroduction = function(_scope)
    {
        var
            scope           =   _scope
        ,   timeline        =   new TimelineLite({
                paused : true,
                onComplete : complete
        })
        ,   $anchors        =   scope.$anchors
        ,   $loader         =   scope.$loader
        ,   $counterTitle1  =   scope.view.find('.counter__title span').eq(0)
        ,   $counterTitle2  =   scope.view.find('.counter__title span').eq(1)
        ,   $counterSubtitle    =   scope.view.find('.counter__subtitle span')
        ,   $dots           =   $loader.find('.dots .dot')
        ,   $timeline       =   scope.view.find('.timeline')
        ,   context         =   _k.getViewport()
        ,   scaleLine       =   500 / context.width


        // Presets
        TweenLite.set(scope.view.find('.timeline__anchors'), { autoAlpha : 1  } )
        TweenLite.set($loader.find('.fill'), { autoAlpha : 0  } )
        TweenLite.set(scope.view.find('.counter__title') , { autoAlpha : 1 })
        TweenLite.set($counterTitle1 , { autoAlpha : 0 })
        TweenLite.set($counterTitle2 , { autoAlpha : 1 })

        // Timeline
        timeline.kill();
        timeline.add([
            TweenLite.fromTo(scope.view.find('.counter__currency') , .5 , { autoAlpha : 0 } , { autoAlpha : 1 } ),
            TweenLite.fromTo(scope.view.find('.counter__number') , .5 , { autoAlpha : 0 } , { autoAlpha : 1 } ),
            TweenLite.fromTo($('#tweenController') , 0 , { scale : 0 } , { scale : 1 , ease : Quart.easeInOut , onUpdate : update , onUpdateParams:["{self}"] } )
        ])
        timeline.restart();

        //
        function update(_el)
        {
            var
            progress = this._targets[0]._gsTransform.scaleX / 1
            ,clipX = 500 * progress
            ,clipXReverse = 500 - (500 * progress)

            TweenLite.set($loader.find('.circle'), {  clip : 'rect(0px,'+clipX+'px,250px,0)'  });
            TweenLite.set($loader.find('.circleReverse'), {  clip : 'rect(250px,500px,500px,'+clipXReverse+'px)' });

            if(clipX == 500)
            {
                $loader.find('.circle').remove();
                $loader.find('.circleReverse').remove();
                $timeline.addClass('is--ready')
            }

            $anchors.each(function(i)
            {
                var
                $ref = $(this)
                ,angleStart = $ref.data('start')
                ,angleEnd = $ref.data('end')
                if(i<4)
                {
                    var
                    distanceToDo = angleEnd - angleStart
                    ,realValue = (distanceToDo * progress) + angleStart
                }
                else
                {
                    var
                    realValue = (angleEnd * progress)
                }
                scope.drawAnchor(realValue,$ref);
            })
        }

        function complete()
        {
            $loader.remove();
            $master.addClass('is--ready')
        }
    }

    // Slideshow Home
    //===============
    Transition.prototype.slideshowHome = function(_scope,_direction)
    {
        var
            scope               =   _scope
        ,   $ref                =   scope.view
        ,   current             =   parseInt($ref.data('index'))
        ,   next                =   scope.currentStrat
        ,   context             =   _k.getViewport()
        ,   moveY               =   (isMobile || isTablet)?$(document).height():context.height
        ,   total               =   scope.total
        ,   $next               =   $ref.find('.item').eq(next)
        ,   $current            =   $ref.find('.item').eq(current)
        ,   duration            =   1
        ;

        moveY = moveY * scope.currentStrat
        if(next > total)
        {
            next = scope.total;
            scope.currentStrat = next;
        }
        else if(next < 0)
        {
            next = 0;
            scope.currentStrat = 0;
        }

        if(_direction > 0)
        {
            scope.updateAnchor();
            setTimeout(function() {
                _k.publish('slideshow::updateFeature',next);
            }, duration * 1000);

        }
        else
        {
            setTimeout(function() {
                scope.updateAnchor();
            }, duration * 1000);
            _k.publish('slideshow::updateFeature',next);
        }


        if(current!=next)
        {
            scope.locked = true;
            TweenLite.to(scope.$slideshow , duration , { scrollTo : { y : moveY } , ease : Quart.easeInOut , onComplete : complete , onStart : start } );
        }

        function start()
        {
            setTimeout(function() {
                _k.publish('slideshow::between',next);
            }, 200);
        }

        function complete()
        {
            scope.locked = false;
            scope.store();
            scope.update();
        }
    }

    // Slideshow generic
    //==================
    Transition.prototype.slideshowGeneric = function(_scope) {
        var
            scope               =   _scope
        ,   $ref                =   scope.$slideshow
        ,   current             =   parseInt($ref.data('index'))
        ,   next                =   scope.currentIndex
        ,   context             =   _k.getViewport()
        ,   moveX               =   (scope.full)?context.width:scope.$slideshow.width()
        ,   $next               =   $ref.find('.jsSlideshow--item').eq(next)
        ,   $current            =   $ref.find('.jsSlideshow--item').eq(current)
        ,   timeline            =   new TimelineLite({
                paused : true
            })
        ,   duration            =   1
        ;


        if(current!=next)
        {
            scope.locked            =   true;
            scope.items.removeClass('dp-no dp-b');
            // timeline
            //=========
            timeline.kill();

            var
                endCurrent      =   (current>next)?moveX:(moveX*-1)
            ,   startNext       =   (current>next)?(moveX*-1):moveX
            ;

            timeline.call(
                function() {
                    $current.css({
                        'z-index' : 2
                    });
                    $next
                        .css({
                            'z-index' : 3
                        })
                        .removeClass('disabled')
                        .addClass('enabled')
                    ;
                    scope.updateInfo();
                }
            );
            timeline.add(
                [
                    TweenLite.fromTo($current,duration ,{ x : 0 , autoAlpha : 1 },{ x : endCurrent , autoAlpha : 0 , ease : Quart.easeInOut, force3D:true }),
                    TweenLite.fromTo($next,duration,{ x : startNext , autoAlpha : 0 },{ x : 0 , autoAlpha : 1 , ease : Quart.easeInOut, force3D:true })
                ]
            );


            timeline.call(
                function() {
                    scope.locked    =   false;
                    TweenLite.set($current,{clearProps:"all" });
                    TweenLite.set($next,{clearProps:"all" });
                    scope.store();
                    scope.update();
                }
            );

            timeline.restart();
        }

    }

    Transition.prototype.tryAnimation = function(_scope)
    {
        var
            scope               =   _scope
        ,   timeline            =   new TimelineLite({
                paused : true
            })
        ,   duration            =   1
        ,   easing              =   Power3.easeOut
        ,   $ref                =   scope.view.find('.dollarsTween')
        ,   $symbols            =   scope.view.find('.dollarsTween .symbol')
        ,   context             =   _k.getViewport()

        // timeline
        timeline.kill();
        timeline.add([
            TweenMax.fromTo($symbols.eq(0), duration, { x : 140 , y : 330 , autoAlpha : 0 , z : 500 } , { y : 350 , autoAlpha : 1 , z : 0 , ease:easing }),
            TweenMax.fromTo($symbols.eq(1), duration, { x : 600 , y : -200 , autoAlpha : 0 , z : 500 } , { y : -100 , autoAlpha : 1 , z : 0 , ease:easing , delay : .01 }),
            TweenMax.fromTo($symbols.eq(2), duration, { x : 280 , y : -400 , autoAlpha : 0 , z : 500 } , { y : -380 , autoAlpha : 1 , z : 0 , ease:easing , delay : .02 }),
            TweenMax.fromTo($symbols.eq(3), duration, { x : -600 , y : -310 , autoAlpha : 0 , z : 500 } , { y : -290 , autoAlpha : 1 , z : 0 , ease:easing , delay : .03 }),
            TweenMax.fromTo($symbols.eq(4), duration, { x : -420 , y : -100 , autoAlpha : 0 , z : 500 } , { y : 0 , autoAlpha : 1 , z : 0 , ease:easing , delay : .04 }),
            TweenMax.fromTo($symbols.eq(5), duration, { x : -540 , y : 330 , autoAlpha : 0 , z : 500 } , { y : 350 , autoAlpha : 1 , z : 0 , ease:easing , delay : .05 }),
            TweenMax.fromTo($symbols.eq(6), duration, { x : -250 , y : -470 , autoAlpha : 0 , z : 500 } , { y : -450 , autoAlpha : 1 , z : 0 , ease:easing , delay : .06 }),
            TweenMax.fromTo($symbols.eq(7), duration, { x : 580 , y : 390 , autoAlpha : 0 , z : 500 } , { y : 410 , autoAlpha : 1 , z : 0 , ease:easing , delay : .07 })
        ])

        timeline.add([
            TweenMax.to($symbols.eq(0), 5, { x:'+=10px', y : '+=10px' , repeat:-1, yoyo:true , ease : Sine.easeInOut }),
            TweenMax.to($symbols.eq(1), 3, { x:'-=15px', y : '-=15px' , repeat:-1, yoyo:true , ease : Sine.easeInOut }),
            TweenMax.to($symbols.eq(2), 6, { x:'+=35px', y : '+=35px' , repeat:-1, yoyo:true , ease : Sine.easeInOut }),
            TweenMax.to($symbols.eq(3), 3, { x:'+=10px', y : '+=10px' , repeat:-1, yoyo:true , ease : Sine.easeInOut }),
            TweenMax.to($symbols.eq(4), 5, { x:'-=30px', y : '-=30px' , repeat:-1, yoyo:true , ease : Sine.easeInOut }),
            TweenMax.to($symbols.eq(5), 4, { x:'+=8px', y : '+=8px' , repeat:-1, yoyo:true , ease : Sine.easeInOut }),
            TweenMax.to($symbols.eq(6), 3, { x:'+=10px', y : '+=10px' , repeat:-1, yoyo:true , ease : Sine.easeInOut }),
            TweenMax.to($symbols.eq(7), 3, { x:'-=15px', y : '-=15px' , repeat:-1, yoyo:true , ease : Sine.easeInOut }),
        ],'-=.2')

        setTimeout(function() {
            timeline.restart();
        }, 1000);

    }

    // Singleton
    var _instance;

}(window, document));